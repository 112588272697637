<template>
    <div class="col-lg-7 col-xl-4">
        <!-- Simple card -->
        <b-card style="border-radius: 13px;">

            <router-link to="" @click="showUpdateModal" style=" color: #303030;">
                <div>
                    <img class="card-img" :src="image" alt="Card image" style="object-fit: cover; opacity: 0.9; ">
                    <div style="
                        top: 20px;
                        height: 200px;
                        width: 90%;
                        position: absolute;
                        border-radius: 10px;
                        z-index: 1;
                        -webkit-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
                        -moz-box-shadow : inset 0px -185px 112px -95px rgba(4,87,17,0.57);
                        box-shadow : inset 0px -185px 112px -95px rgba (4,87,17,0.57);
                        "></div>
                    <h4 style="position:absolute; top:194px; left: 30px; color: #fff; z-index: 11;">{{ time }}</h4>
                </div>

                <b-card-title>
                    <h5 class="card-title"
                        style=" font-size: 1.4em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 20px;">
                        {{ title }}</h5>
                </b-card-title>
                <b-card-text
                    style="font-size: 1.2em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; -webkit-box-orient: vertical; margin-bottom: 20px;">
                    <div>
                        {{ description }}
                    </div>
                </b-card-text>
            </router-link>
            <b-button class="c2play-primary" @click="showUpdateModal" style="margin-left: 38%; margin-right: auto;">
                {{ $t('updateButton') }}
            </b-button>
        </b-card>
    </div>

    <b-modal v-model="updateModal" id="modal-xl" size="xl" :title="$t('pages.updateChannel.formulaire.title')"
        title-class="font-18" hide-footer>
        <div class="card-body">
            <form>

                <div class="row mb3">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="projectname" class="col-form-label">{{ $t('pages.channels.formulaire.link.label')
                            }}</label>
                            <div class="col-lg-12">
                                <input id="projectname" name="projectname" type="text" class="form-control"
                                    :placeholder="$t('pages.channels.formulaire.link.placeholder')" v-model="channel.url" />
                            </div>
                        </div>
                    </div>
                </div><br>
            </form>
            <div class="row">
                <div class="col-lg-10">
                    <button type="submit" class="btn c2play-primary" @click="updateChannel">
                        {{ $t('updateButton') }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>
  
<script>
import { Api } from '../../helpers';
import { Erreur } from '../../helpers/error';
export default {
    data() {
        return {
            updateModal: false,
            channel: {
                titre: "",
                image: "",
                url: "",
                description: "",
            }
        }
    },
    props: {
        id: {
            default: ""
        },
        image: {
            default: ""
        },
        title: {
            default: ""
        },
        description: {
            default: ""
        },
        url: {
            default: ""
        },
    },
    methods: {
        updateChannel() {
            this.process = true
            this.modalOk = false
            Api.put(`/streamvod/rest/channel/update-flux/${this.id}?channel_url=${this.channel.url}`)
                .then((response) => {
                    console.log(response)
                    location.reload()
                }).catch((error) => {
                    this.process = false
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },


        showUpdateModal() {
            this.updateModal = true
        },


    },
    mounted() {
        this.channel.titre = this.title
        this.channel.description = this.description
        this.channel.url = this.url
        this.channel.image = this.image
    }
}
</script>
  
<style>
.card-img {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 9px;
    margin-bottom: 20px;
}
</style>