<script>
var moment = require('moment');
moment.locale ('fr');
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { Api } from '../../../helpers';
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from '../../../components/widgets/loaderProcess.vue';
import liveFluxCard from "../../../components/widgets/liveFluxCard.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
    liveFluxCard
  },
  
  data() {
    return {
      modalOk:false,
      process :false,
      channels:[],
      channelInfo:{
        id:"",
        description:"",
        name:"",
        logo:"",
        url:""
      },   
      moment:moment,
      uniqueChannel:[],
      title: "Consultez le flux des différentes chaines",
      items: [
        {
          text: "Gestion des chaines",
          // href: "/projects/channels",
        },
        {
          text: "Flux du live",
          active: true,
        },
      ],
    };
  },
  
  mounted(){
      Api.get("/streamvod/api/channel/channel-all")
      .then((response) =>{
        this.channels = response.data.content
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    

    <br>
    <div class="row">
      <liveFluxCard
      v-for="(channel, index) in channels" :key="index" :image="channel.channel_logo.url"
        :title="channel.channel_name" :description="channel.channel_description" :url="channel.channel_url" :id="channel.id"
      ></liveFluxCard>

      <!-- end col -->
    </div>

    
                  
    <!-- end row -->
    <loaderProcessVue v-if="process == true"></loaderProcessVue>
  </Layout>
</template>

<style>
  
</style>